<v-dialog hide-overlay max-width="36em" v-model="isActive">
	<v-card class="accent newsletter-overlay pt-3 px-3">
		<v-card-title>
			<v-row>
				<v-col>
					<h2 class="white--text">Newsletter abonnieren</h2>
				</v-col>
				<v-col cols="auto">
					<v-btn :icon="$icons.mdiClose" color="white" variant="text" @click="hide"></v-btn>
				</v-col>
			</v-row>
		</v-card-title>
		<v-card-text>
			<p class="white--text">Wir stellen regelmässig nützliche Infos zusammen, entwickeln neue, spannende Kurse und kreieren Spezialangebote und Aktionen. Möchtest du frühzeitig informiert werden? Dann abonniere unseren Newsletter.</p>

			<v-container fluid class="pa-0">
				<v-row justify="center" class="newsletter-overlay__layout">
					<v-col sm="6" cols="12">
						<v-text-field variant="solo" hide-details="auto" label="Vorname" placeholder="Erika" autocomplete="given-name" v-model="data.firstname" :error-messages="validations.getErrors('firstname')" @update:modelValue="validate('firstname')" />
					</v-col>
					<v-col sm="6" cols="12">
						<v-text-field variant="solo" hide-details="auto" label="Nachname" placeholder="Muster" autocomplete="family-name" v-model="data.lastname" :error-messages="validations.getErrors('lastname')" @update:modelValue="validate('lastname')" />
					</v-col>
				</v-row>
				<v-row justify="center" class="newsletter-overlay__layout">
					<v-col cols="auto" class="newsletter-overlay__greeting pr-4">
						<v-radio-group hide-details="auto" dark class="mt-0" v-model="data.gender" :error-messages="validations.getErrors('gender')" @update:modelValue="validate('gender')">
							<v-radio color="white" :label="femaleLabel" value="female" />
							<v-radio color="white" :label="maleLabel" value="male" />
						</v-radio-group>
					</v-col>
					<v-col>
						<v-text-field variant="solo" class="mt-2" hide-details="auto" label="E-Mail" placeholder="erikamuster@example.com" autocomplete="email" v-model="data.email" :error-messages="validations.getErrors('email')" @update:modelValue="validate('email')" />
					</v-col>
				</v-row>
				<v-row justify="center">
					<v-col cols="auto">
						<v-btn size="large" color="primary" @click="send" :loading="state === 'loading'">Newsletter Abonnieren</v-btn><br />
						<a class="newsletter-overlay__close-link" @click="hide">Nein danke</a>
					</v-col>
				</v-row>
			</v-container>

			<v-snackbar top center color="success" :modelValue="state === 'success'">
				<p>Vielen Dank für deine Anmeldung.</p>
			</v-snackbar>
			<v-snackbar top center color="error" :modelValue="state === 'error'">
				<p>Das Formular konnte aus technischen Gründen nicht gesendet werden.</p>
				<p>Bitte kontaktiere uns per Telefon unter 033 222 59 59 oder schreibe uns eine Mail an <a href='mailto:mail@anisana.ch'>mail@anisana.ch</a>.</p>
				<p>Vielen Dank für dein Verständnis.</p>
			</v-snackbar>
		</v-card-text>
	</v-card>
</v-dialog>
