<v-card class="download-order-form bg-grey-lighten-4 pa-2 mb-4">
	<v-card-text>
		<v-row>
			<v-col>
				<div class="clearfix" v-html="contentHtml"></div>
			</v-col>
			<v-col cols="auto">
				<v-btn :icon="$icons.mdiClose" variant="text" @click="$emit('close')" v-if="isModal"></v-btn>
			</v-col>
		</v-row>

		<v-container class="pa-0 clearfix">
			<v-row>
				<v-col cols="6">
					<v-text-field hide-details="auto" label="Vorname" placeholder="Maxine" autocomplete="given-name" v-model="data.firstname" :error-messages="validations.getErrors('firstname')" @update:modelValue="validate('firstname')" />
				</v-col>
				<v-col cols="6">
					<v-text-field hide-details="auto" label="Nachname" placeholder="Muster" autocomplete="family-name" v-model="data.lastname" :error-messages="validations.getErrors('lastname')" @update:modelValue="validate('lastname')" />
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12">
					<v-text-field hide-details="auto" label="E-Mail" placeholder="maxinemuster@example.com" autocomplete="email" v-model="data.email" :error-messages="validations.getErrors('email')" @update:modelValue="validate('email')" />
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="auto">
					<v-radio-group class="mt-0" row v-model="data.gender" :error-messages="validations.getErrors('gender')" @update:modelValue="validate('gender')">
						<v-radio :label="femaleLabel" value="female" />
						<v-radio :label="maleLabel" value="male" />
					</v-radio-group>
				</v-col>
				<v-col></v-col>
				<v-col cols="auto">
					<v-checkbox label="Newsletter abonnieren" class="mt-1" hide-details v-model="signupForNewsletter" />
				</v-col>
			</v-row>
		</v-container>
		<v-btn color="accent" :loading="state === 'loading'" @click="send">Praxisbeispiel anfordern</v-btn>
	</v-card-text>

	<v-snackbar top center color="success" :modelValue="state === 'success'">
		<p>Vielen Dank für dein Interesse. Den Download-Link findest du in Kürze in deiner Mailbox – viel Spass beim Lesen!</p>
	</v-snackbar>
	<v-snackbar top center color="error" :modelValue="state === 'error'">
		<p>Das Formular konnte aus technischen Gründen nicht gesendet werden.</p>
		<p>Bitte kontaktiere uns per Telefon unter 033 222 59 59 oder schreibe uns eine Mail an <a href='mailto:mail@anisana.ch'>mail@anisana.ch</a>.</p>
		<p>Vielen Dank für dein Verständnis.</p>
	</v-snackbar>
</v-card>
