import { Vue, Prop, Component, Watch } from "@wagich/vue-facing-decorator-metadata";

import { render } from "./contact-form-button.html";
import { ContactForm } from "~/components/contact-form";

@Component({
	render,
	components: {
		ContactForm,
	}
})
export class ContactFormButton extends Vue {
	isOpen: boolean = false;

	@Prop({ default: "" }) source: string;
	@Prop({ required: false }) token: string | null;

	cancel() {
		this.isOpen = false;
	}

	success() {
		this.isOpen = false;
	}
}
