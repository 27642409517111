<v-card class="newsletter-signup" :flat="flat" :class="{'bg-grey-lighten-4': !flat}">
	<v-card-title>
		<v-row>
			<v-col>
				<h2>Newsletter abonnieren</h2>
			</v-col>
			<v-col cols="auto">
				<v-btn :icon="$icons.mdiClose" variant="text" @click="$emit('close')" v-if="isModal"></v-btn>
			</v-col>
		</v-row>
	</v-card-title>
	<v-card-text>
		<p>Wir stellen regelmässig nützliche Infos zusammen, entwickeln neue, spannende Kurse und kreieren Spezialangebote und Aktionen. Möchtest du frühzeitig informiert werden? Dann abonniere unseren Newsletter.</p>

		<v-row>
			<v-col cols="6">
				<v-text-field hide-details="auto" label="Vorname" placeholder="Erika" autocomplete="given-name" v-model="data.firstname" :error-messages="validations.getErrors('firstname')" @update:modelValue="validate('firstname')" />
			</v-col>
			<v-col cols="6">
				<v-text-field hide-details="auto" label="Nachname" placeholder="Muster" autocomplete="family-name" v-model="data.lastname" :error-messages="validations.getErrors('lastname')" @update:modelValue="validate('lastname')" />
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4">
				<v-radio-group class="mt-0" hide-details="auto" v-model="data.gender" :error-messages="validations.getErrors('gender')" @update:modelValue="validate('gender')">
					<v-radio :label="femaleLabel" value="female" />
					<v-radio :label="maleLabel" value="male" />
				</v-radio-group>
			</v-col>
			<v-col cols="8">
				<v-text-field class="mt-2" hide-details="auto" label="E-Mail" placeholder="erikamuster@example.com" autocomplete="email" v-model="data.email" :error-messages="validations.getErrors('email')" @update:modelValue="validate('email')" />
			</v-col>
		</v-row>
		<v-btn size="large" color="accent" @click="send" :loading="state === 'loading'">Abonnieren</v-btn>

		<v-snackbar top center color="success" :modelValue="state === 'success'" :timeout="10000">
			<p>Vielen Dank für deine Anmeldung.</p>
		</v-snackbar>
		<v-snackbar top center color="error" :modelValue="state === 'error'" :timeout="10000">
			<p>Das Formular konnte aus technischen Gründen nicht gesendet werden.</p>
			<p>Bitte kontaktiere uns per Telefon unter 033 222 59 59 oder schreibe uns eine Mail an <a href='mailto:mail@anisana.ch'>mail@anisana.ch</a>.</p>
			<p>Vielen Dank für dein Verständnis.</p>
		</v-snackbar>
	</v-card-text>
</v-card>
